import React from 'react'
import { List } from 'semantic-ui-react'


import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <h1>2018 Year-end recap and review</h1>
    <p>I've been at Turner for almost exactly a year now, and over that year I believe I have integrated well into the company and the development team. I have tried to quickly learn the technologies and workflow of this industry and this specific team, while also bringing my experience to improve our system. There are a number of initiatives that I have contributed to and helped lead that have positively impacted our goal of creating and retaining new and existing fans. I have also tried to incorporate feedback and continue to grow as a developer.</p>
    <h2>About this project:</h2>
    <p>
      To review my year, I've built this site with some of the knowledge I've gained over the year. Technologies used for this include:
    <List bulleted>
        <List.Item>React</List.Item>
        <List.Item>GraphQL</List.Item>
        <List.Item>Drupal</List.Item>
        <List.Item>Semantic React UI</List.Item>
        <List.Item>Gatsby</List.Item>
        <List.Item>Netlify</List.Item>
      </List>
      It's also heavily inspired by a talk given by Matt Fleury at DrupalCamp Atlanta
    </p>
  </Layout>
)

export default IndexPage
